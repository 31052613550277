@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "circular";
  src: url("./assets/real-font/CircularStd-Book.woff2");
  font-weight: 400;
}

@font-face {
  font-family: "circular";
  src: url("./assets/real-font/CircularStd-Bold.woff2");
  font-weight: 700;
}
@font-face {
  font-family: "circular";
  src: url("./assets/real-font/CircularStd-BoldItalic.woff2");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "circular";
  src: url("./assets/real-font/CircularStd-Medium.woff2");
  font-weight: 500;
}

@font-face {
  font-family: "Cabinet Grotesk-Medium";
  src: url("./assets/real-font/cabinetgrotesk-medium.otf") format("opentype");
}

@font-face {
  font-family: "circular";
  src: url("./assets/real-font/cabinetgrotesk-medium.otf") format("opentype");
}

.flow-hide {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.flow-hide-x-1 {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.flow-hide-x {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.flow-hide-x::-webkit-scrollbar {
  width: 0;
  height: 0;
  cursor: pointer;
}
.flow-hide::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.hide-scroll {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.login-box {
  background: #f9f9f9;
  box-shadow: 6px 5px 24px rgba(0, 0, 0, 0.17);
}

.cap {
  background: #d3a518;
  border-radius: 5px 5px 0px 0px;
  height: 16px;
}

.card-cap {
  background: #041c40;
  border-radius: 10px 10px 0px 0px;
  height: 16px;
}

@media (min-width: 580px) {
  .layout-bg {
    background: url("./assets//png//bg-books.jpg");
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-position: center;
    background-size: cover;
  }
}

.brief-card-bg {
  background: #f9f9f9;
  box-shadow: 6px 5px 24px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
}

.arrow-circle {
  background: #ffffff;
  box-shadow: 0px 2.61743px 13.0872px rgba(217, 217, 217, 0.25);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-max {
  z-index: 100;
}
.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .p_hidden {
    display: hidden;
  }
}
